import React, {useState} from "react"
import './styles/main.scss'
import Header from './components/layout/Header'
import MainPage from './components/pages/Home'

function App() {
  return (
    <React.Fragment>
      <Header/>
      <main>
        <MainPage/>
      </main>
    </React.Fragment>
  )
}

export default App
