import React from "react"

import { ScrollButton } from "../Util"

function TeamMember(props) {
  return (
    <div className="d-flex m-1">
      <figure className="figure mx-auto">
        <img className="figure-img img-fluid rounded" style={ { maxHeight: "30vh" } } src={ props.image } alt={ props.name } />
        <figcaption className="figure-caption text-center text-light">{ props.name }</figcaption>
        <figcaption className="figure-caption text-center text-light"><strong>{ props.role }</strong></figcaption>
      </figure>
    </div>
  )
}

function ContactInfo(props) {
  return (
    <div className="py-2">
      <p className="my-0"><strong>{ props.name }</strong></p>
      <p className="my-0">Extension { props.extension }</p>
      <p className="my-0">{ props.email }</p>
    </div>
  )
}

function HomePage() {
  return (
    <React.Fragment>
      <section style={ landingSection } className="d-flex flex-column justify-content-center">
        <div style={ heroImageSection } className="mx-auto">
          <img style={ { maxHeight: '74vh' } } className="img-fluid py-3" src="/resources/images/odbox-real-35.png" alt="ODbox"/>
        </div>
        <div style={ scrollButtonContainer } className="d-flex flex-column align-items-center justify-content-center">
          <h4 className="text-light text-center">Committed to Solving the Opioid Epidemic</h4>
          <ScrollButton css_selector="#main-page-content"/>
        </div>
      </section>
      <div id="main-page-content" className="container text-light">
        <div className="row pt-3 mt-5 mb-xs-3 mb-sm-4 mb-md-5">
          <div className="col-sm text-left text-lg-center border-right">
            <h3>What is ODbox?</h3>
            <p>In essence, the ODbox is a device that turns bystanders into emergency responders, giving them the tools to save lives.</p>
            <p>We are increasing public access to a pharmaceutical that stops opioid overdoses, named Narcan (naloxone hydrochloride).</p>
            <p>We are creating boxes, similar to AED boxes, stationed around buildings near opioid-death hotspots, so that bystanders can access it during emergencies, and potentially save lives.</p>
          </div>
          <div className="col-sm text-right text-lg-center border-left">
            <h3>Why ODbox?</h3>
            <p>Between February 2018 and February 2019, the CDC reported 67,029 drug overdose deaths in the United States alone.</p>
            <p>One of the main causes for this number is the lack of accessibility to naloxone hydrochloride, a life-saving opioid reversal drug.</p>
            <p>In America, the Opioid crisis has morphed into an epidemic, and it is our duty as a nation to address it.</p>
            <p>We are a team dedicated to facing down the opium crisis by empowering and equipping communities to save lives.</p>
          </div>
        </div>
        <div style={ { minHeight: '50vh' } } className="d-flex align-items-center py-4 justify-content-center">
          <div className="embed-responsive embed-responsive-16by9">
            <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/jedh48iR7B8" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; fullscreen; picture-in-picture" allowFullScreen></iframe>
          </div>
        </div>
        <div style={ { minHeight: '60vh' } }>
          <h3 className="text-center mx-auto my-xs-3 my-sm-4 my-md-5">Our Team</h3>
          <div id="our-team" className="mt-2 row row-cols-md-3 row-cols-sm-2 justify-content-center">
            <TeamMember name="Kanishk Nazareth" role="Head of Operations" image="/resources/images/kanishk-nazareth.jpg"/>
            <TeamMember name="Sridaran Thoniyil" role="Head of Technology" image="/resources/images/sridaran-thoniyil-formal-cropped-2.png"/>
          </div>
        </div>
      </div>
      <div style={ { minHeight: '55vh' } } className="container text-light py-5">
        <h3 className="text-center mx-auto mb-4">Contact Us</h3>
        {/* <hr className="border-white"/> */}
        <div className="row">
          <div className="col-sm border-white border-right d-flex align-items-center">
            <div style={ { fontSize: "1.2rem" } }className="mx-auto">
              <p><strong>Email: </strong>info@od-box.org</p>
              <p><strong>Phone: </strong>215-918-9507</p>
              <p><strong>Instagram: </strong>@theodboxco</p>
            </div>
          </div>
          <div className="col-sm border-white border-left d-flex flex-column">
            <div className="mx-auto">
              <ContactInfo className="pt-0" name="Kanishk Nazareth" extension="1200" email="kanishknazareth@od-box.org"/>
              <ContactInfo name="Sridaran Thoniyil" extension="1201" email="sridaranthoniyil@od-box.org"/>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

// header height is 8vh
// make the landing section take up tthe rest of the screen
const landingSection = {
  height: '92vh'
};

const heroImageSection = {
  height: '72vh'
};

const scrollButtonContainer = {
  height: '18vh',
  maxHeight: '18vh'
};

const fullScreen = {
  minHeight: '100vh'
};

const demoVideo = {
  height: '70vh'
};

export default HomePage
