import React from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'

// https://codepen.io/bisaillonyannick/pen/pvZeGg

function ScrollButton(props) {
  return (
    <div className="arrow bounce p-0 m-0 mt-md-4 mt-sm-3 mt-2">
      <a className="text-decoration-none text-white" href={ props.css_selector }>
        <FontAwesomeIcon icon={ faArrowDown } size="2x"/>
      </a>
    </div>
  )
}

export {
  ScrollButton
};
