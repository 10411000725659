import React from "react"
import Headroom from "react-headroom"

function Header() {
  return (
    <Headroom>
      <nav className={ 'navbar py-2 px-3 navbar-expand-md navbar-light bg-light h-100' }>
        <a className={ 'navbar-brand p-0' } href='#'>ODbox</a>
        {/* <button className={ "navbar-toggler" } type='button' data-toggle='collapse' data-target='#odboxNavbarLinks' aria-controls='odboxNavbarLinks' aria-expanded='false' aria-label='Toggle navigation'> */}
        {/*   <span className={ 'navbar-toggler-icon' }/> */}
        {/* </button> */}
        {/* <div className={ 'collapse navbar-collapse' } id={ 'odboxNavbarLinks' }> */}
        {/*   <div className={ 'navbar-nav mr-auto mt-1 mt-lg-0 navbar-light' }> */}
        {/*     <a className={ 'nav-link active' } href='#'>Home<span className={ 'sr-only' }>(current)</span></a> */}
            {/* <a className={ 'nav-link' } href='#'>Locations</a> */}
            {/* <a className={ 'nav-link' } href='#'>How We Work</a> */}
            {/* <a className={ 'nav-link' } href='#'>Volunteer</a> */}
            {/* <a className={ 'nav-link' } href='#'>Manuals</a> */}
        {/*   </div> */}
        {/* </div> */}
      </nav>
    </Headroom>
  )
}

export default Header
